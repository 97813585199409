#insightsection ul {
  list-style: disc;
  padding-left: 20px;
  font-weight: 500;
  color: #1f2e39;
}

#insightsection li > ul {
  list-style: circle;
  font-weight: 400;
  color: #1f2e39;
}

#insightsection li::marker {
  color: #1f2e39;
}
