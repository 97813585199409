.fullwidthcontainer {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerblock {
  position: absolute;
  display: block;
  padding: 4.5vh 5vw;
  background-image: url("../../../../public/img/Border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 1300px;
  max-width: 100%;
  max-height: max-content;
}

/* Small screens (up to 600px) */
@media only screen and (max-width: 600px) {
  .containerblock {
    position: absolute;
    display: block;
    padding: 4.5vh 5vw;
    /* background-image: url("/img/Border.png"); */
    /* background-repeat: no-repeat;
    background-size: 120% 120%; */
    background-color: white;
    width: 1300px;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: none;
    max-height: 97vh;
  }
  .containerblock {
    padding: 2vh 2vw;
    border-radius: 10px;
    position: absolute;
    width: 1300px;
    max-width: 95%;
    display: block;
  }
}

/* Medium screens (601px to 1024px) */
@media only screen and (min-width: 600px) and (max-width: 800) {
  .containerblock {
    position: absolute;
    display: block;
    padding: 4.5vh 5vw;
    /* background-image: url("./img/Border.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: auto;
    scrollbar-width: none;
  }

  .containerblock {
    padding: 3vh 4vw;
    position: absolute;
    width: 94%;
    display: block;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 97%;
    display: block;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 98%;
    display: block;
  }
}

/* Large screens (1025px to 1600px) */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 97%;
    display: block;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 95%;
    display: block;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 92%;
    display: block;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2000px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 90%;
    display: block;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2200px) {
  .containerblock {
    padding: 4.5vh 5vw;
    position: absolute;
    width: 88%;
    display: block;
  }
}
