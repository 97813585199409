.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.logo {
  height: 30px;

}

.heading {
  height: 30px;
  font-size: 28px;
  text-align: center;
  font: normal normal bold 30px/23px Rubik;
  letter-spacing: 3px;
  color: #1f2e39;
  text-transform: uppercase;
  margin: 0;
}

.linkcontainer {
  display: flex;
  align-items: center;
  gap: 40px;
}

.link {
  color: #1FC89F;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  line-height: 18px;
  font: normal normal medium 15px/23px Rubik;
  /* border-bottom: 1px solid #1f2e39; */
}

.linkactive {
  color: #1FC89F;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  line-height: 18px;
  font: normal normal medium 15px/23px Rubik;
  cursor: text;
}

.headingmobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .header {
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    margin-bottom: 15px;
  }

  .logo {
    height: 20px;
    margin-top: 8px;
   
  }

  .heading {
    display: none;
  }

  .linkactive,
  .link {
    font-size: 16px;
  }

  .headingmobile {
    font-size: 25px;
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .linkcontainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left:0px;
    padding-right: 0px;
  }
} 
@media only screen and (max-width: 500px) and (min-width: 300px) {
  /* Styles for screens between 300px and 500px */
  .link {
    display: inline-block; /* Ensure all links are displayed */
    width: 100%; /* Adjust width to show four buttons */
  }
  
  .linkcontainer {
    gap: 10px;
  }
}



 /* @media only screen and (max-width: 300px) {
  .header {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo {
    height: 20px;
  }

  .heading {
    display: none;
  }

  .linkactive,
  .link {
    font-size: 12px;
  }

  .headingmobile {
    font-size: 25px;
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .linkcontainer {
    gap: 15px;
  }
}  */
