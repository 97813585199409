.analyst1 {
  margin-left: 20px;
}
/* .notify-parent {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
} */
.analyst-child1 {
  color: #1FC89F;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}
.analyst-cjild1 {
  color: #1f2e39;
  font-size: 28px;
  font-weight: bold;
  margin-left: 20px;
}

.analyst-datails1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  justify-items: end;
  width: 100%;
}
.analist-child1 {
  margin-right: 470px; /* Adjust this value to match the previous gap */
}
.analyst-chhild1 {
  color: #708ea4;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.button-parent1 {
  display: flex;
  flex-direction: row;
  gap: 18px;
}
.button-child1 {
  color: #708ea4;
  font-size: 13px;
  font-weight: 400;
  /* margin-top: 12px; */
}
.analist-cjild1 {
  color: #1FC89F;
  font-size: 14px;
  font-weight: 500;
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;
}
.button-box {
  display: flex;
  width: 145px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  border: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background-size: 150% 100%;
  background-position: 100% 0;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.3s ease;
  cursor: pointer;
}
/* .button-box:hover {
  background-size: -150% -100%;
  background: linear-gradient(
    to bottom left,
    #22d6aa 0%,
    #22d6aa 50%,
    #22d6aa 50%,
    #22d6aa 100%
  );
  background-position: 100% 0;
} */
.back-button {
  color: #1f2e39;
  font-size: 13px;
  font-weight: 600;
}
.icon-box {
  transition: transform 0.3s ease; /* Apply transition effect to icon */
}
.button-box:hover .icon-box {
  transform: rotate(45deg); /* Rotate icon on hover */
}
.notify-child {
  display: flex;
  flex-direction: row;
  /* gap:10px; */
  /* margin-top: 10px; */
  margin-bottom: -18px;
}

/*Listing*/
.john {
  margin-top: 10px;
  padding: 10px 10px;
  background-color: #edf4ff;
  border-radius: 5px;
}
.details-listing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 8px;
  margin-top: 10px;
}
.under-listing {
  color: #1f2e39;
  font-weight: 600;
  font-size: 14px;
}
.complete-list {
  display: flex !important;
  flex-direction: row !important;
}
.full-list {
  width: 100%;
  margin-left: 10px;
}

.dropdown_sort.secondopen {
  border-radius: 5px 5px 0 0;
  color: black;
}
.dropdown_sort {
  background-color: white;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 20px #5b93ed33;
  height: 5px;
  font-size: 15px;
}
.dropdown_sort_by {
  position: relative;
  margin-bottom: 20px;
}
.dropdown_sort_by_list {
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0px 10px 20px #5b93ed33;
  border-radius: 0 0 5px 5px;
}
.dropdown_sort_by_list p {
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  border-top: 2px solid #5b93ed33;
  border-bottom: 0px solid #5b93ed33;
  font-size: 15px;
  padding: 5px;
}
.dropdown_sort_by_list_mobile {
  position: absolute;
  background-color: white;
  width: 92%;
  box-shadow: 0px 10px 20px #5b93ed33;
  border-radius: 0 0 5px 5px;
}
.dropdown_sort_by_list_mobile p {
  /* margin-right: 15px;
  margin-left: 15px; */
  cursor: pointer;
  border-top: 2px solid #5b93ed33;
  border-bottom: 0px solid #5b93ed33;
  font-size: 15px;
  padding: 5px;
}

@media screen and (max-width: 820px) {
  .complete-list {
    display: flex !important;
    flex-direction: column !important;
  }
  .full-list {
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .pagination-wrapper {
    background-color: white;
  }
  .jobid-more-mobile {
    background: url(/public/images/Green-Box.svg);
    margin: 13px 15px 13px 15px;
    background-repeat: no-repeat;
    border-radius: 5px;
    background-size: cover;
    padding: 10px 10px;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
  }
  .stopper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 4px;
    box-shadow: 0px 4px 6px rgba(91, 147, 237, 0.2);
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #edf4ff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    bottom: 1px;
    background-color: #708ea4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider:before {
    background-color: #1FC89F; /* Change the circle background color here */
    transform: translateX(20px);
  }

  input:checked + .slider {
    background-color: rgba(33, 214, 170, 0.4);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
