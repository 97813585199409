.descriptionarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #22d6aa33;
  border-radius: 5px;
  padding: 15px;
  height: 71.5vh;
  width: 100%;
  /* overflow: hidden; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 15px;
  text-align: justify;
}
/* rate this much button styling */
.buttonboxsecond {
  display: flex;
  width: 145px;
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  border: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background-size: 150% 100%;
  background-position: 100% 0;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.3s ease;
}
.buttonboxsecond:hover {
  background-size: -150% -100%;
  background: linear-gradient(
    to bottom left,
    #22d6aa 0%,
    #22d6aa 50%,
    #22d6aa 50%,
    #22d6aa 100%
  );
  background-position: 100% 0;
}
.backbuttonsecond {
  color: #1f2e39;
  font-size: 13px;
  font-weight: 600;
}
.buttonboxsecond:hover .ratethismatch {
  display: none;
}

.iconboxsecond {
  width: 15px;
  height: 15px;
  transition: transform 0.3s ease; /* Apply transition effect to icon */
  display: block; /* Ensure the icon is displayed as a block element */
  margin: 0 auto; /* Center the icon horizontally */
}

.buttonboxsecond:hover .iconboxsecond {
  /* transform: translate(-50%, -50%); */
  transform: translateX(10%);
  width: 25px;
  height: 25px;
}

/*  */
/* submit button styling */

.buttonbox {
  display: flex;
  width: 145px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  border: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background: linear-gradient(to bottom left, #22d6aa 50%, #1dc59c 50%);
  background-size: 150% 100%;
  background-position: 100% 0;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.3s ease;
}
.buttonbox:hover {
  background-size: -150% -100%;
  background: linear-gradient(
    to bottom left,
    #22d6aa 0%,
    #22d6aa 50%,
    #22d6aa 50%,
    #22d6aa 100%
  );
  background-position: 100% 0;
}
.backbutton {
  color: #1f2e39;
  font-size: 13px;
  font-weight: 600;
}

.iconbox {
  transition: transform 0.3s ease; /* Apply transition effect to icon */
}
.buttonbox:hover .iconbox {
  transform: rotate(45deg); /* Rotate icon on hover */
}

.btnToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  background-color: #22d6aa;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 40px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 14px;
  letter-spacing: 1px;
}
.headerheading {
  margin-bottom: 20px;
}
.btnToggle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -12%;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #22d6aa;
  transform: translateY(-50%) rotate(60deg);
}
.clicked::after {
  border-left-color: white;
  transform: translateY(-50%) rotate(0deg);
  left: -4%;
}

.rankingtable {
  height: 65vh;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  margin-bottom: 20px;
  margin-left: 50px;
}

.tabledata {
  border: 0;
  width: 100%;
  border-bottom: 10px solid #5a93ed;
}

.thead {
  background-color: #5a93ed;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
}

.thead th {
  padding: 10px;
}

.backgroundcell {
  background-color: #cbdfff;
  font-size: 12px;
  color: #1f2e39;
  padding: 10px;
  font-weight: 500;
}

.cell {
  background-color: #ffffff;
  font-size: 12px;
  color: #1f2e39;
  padding: 10px;
  font-weight: 500;
}

.borderbottom {
  border-bottom: 1.5px solid #6f8ea4;
}

.txtdarkgreen {
  background-color: #0f9f7c;
}

.txtmedgreen {
  background-color: #96dfcd;
}

.txtlightgreen {
  background-color: #b7efd1;
}

.txtdarkyellow {
  background-color: #fff348;
}

.txtdarkpink {
  background-color: #edd5b4;
}

.txtred {
  background-color: #e4b7bf;
}

.headerheading {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.summary {
  font-size: 12px;
  font-weight: 500;
  color: #1f2e39;
}

.btnanalyse {
  padding: 15px;
  background: #22d6aa 0% 0% no-repeat padding-box;
  border: 2px solid #22d6aa;
  color: #1f2e39;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.btnanalyseicon {
  height: 20px;
}

.btnanalyse:hover {
  background: #22d6aa;
}

@media only screen and (max-width: 600px) {
  .searchtitle {
    font-size: 15px;
    margin: 0;
  }

  .descriptionarea {
    font-size: 12px;
    gap: 10px;
    height: 32vh;
    margin-bottom: 15px;
    overflow-y: auto;
  }

  .rankingtable {
    height: 40vh;
    max-height: 35vh;
  }
  .thead {
    font-size: 12px;
  }

  .thead th {
    padding: 8px;
  }

  .cell {
    font-size: 12px;
    padding: 8px;
  }
  .txtmedgreen {
    font-size: 12px;
  }
  .tabledata {
    max-width: 100%;
    overflow-x: scroll;
  }

  .btnanalyse {
    font-size: 12px;
    padding: 12px;
    gap: 15px;
  }
}
.highly {
  font-size: 14px;
  color: #1f2e39;
}

.helthcare {
  font-size: 14px;
  color: #1f2e39;
}

.match-dta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.p-match {
  font-size: 20px;
  font-weight: bold;
  color: #1f2e39;
  background: white;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.p-job {
  color: #1FC89F;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

/* .p-Rank {
  color: #1F2E39;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
} */

.fulldiv {
  margin-left: 15px;
}

.match-dta2 {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.MuiAccordionSummary-button {
  background-color: #1FC89F !important;
  border-radius: 5px !important;
}
.MuiSvgIcon-root {
  fill: white !important;
}

.iconboxsecond {
  width: 15px;
  height: 15px;
  transition: transform 0.3s ease;
  display: block;
  margin: 0 auto;
}

.prosandcons {
  color: #1f2e39;
  font: normal normal bold 15px/23px Rubik;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: #ffffff;
  color: #1f2e39;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 80%;
  text-align: center;
}

.popup img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
}

.popup p {
  font: normal normal bold 15px/18px "Rubik", sans-serif;
  margin-top: 10px;
}

.closeButton {
  color: #1FC89F;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: 15px;
}
