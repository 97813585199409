.resumecontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  width: 100%;
}

.searchtextcontainer {
  width: 30%;
}

.filesuploadcontainer {
  flex: 2;
}

.errorText {
  color: red;
  font-size: 12px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: #ffffff;
  color: #1f2e39;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 80%;
  text-align: center;
}

.popup img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
}

.popup p {
  font: normal normal bold 15px/18px "Rubik", sans-serif;
  margin-top: 10px;
}

.closeButton {
  color: #1FC89F;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.searchcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.searchtitle {
  color: #1f2e39;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  font: normal normal bold 17px/23px Rubik;
  margin: 0;
}

.searchlink {
  display: flex;
  font-size: 15px;
  text-align: left;
  font: normal normal medium 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1FC89F;
  text-transform: uppercase;
  gap: 10px;
  align-items: center;
}

.pasteicon {
  height: 15px;
  width: auto;
}

.pastetextarea {
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #708ea4;
  border-radius: 5px;
  width: 100%;
  height: 40vh;
  font-size: 15px;
  text-align: left;
  font: normal normal normal 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  padding-left: 5px;
}

.pastetextarea2 {
  outline: none;
  /* background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #708ea4; */
  border-radius: 5px;
  width: 100%;
  height: 40vh;
  font-size: 15px;
  text-align: left;
  font: normal normal normal 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
}

.searchfilecontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.searchfilecontainerforSource {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 0px 30px #22d6aa33;
  border-radius: 5px;
}

.uploadingcontainer {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 50px;
  padding: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #22d6aa33;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.uploadingcontainerforSource {
  width: 50%;
  background-color: #ffffff;
  padding: 40px;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 70%;
  display: flex;
  flex-direction: column;
}

.uploadicon {
  height: 25px;
  width: auto;
  margin-bottom: 20px;
}

.uploadmorecontainer {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: -150px;
}

.uploadtitle {
  text-align: center;
  font: normal normal bold 20px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  text-transform: capitalize;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadtitlenote {
  line-height: 18px;
  font-size: 15px;
  text-align: center;
  font: normal normal normal 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonbrowse {
  padding: 20px;
  background: transparent url("../../../../public/img/button1.svg") 0% 0%
    no-repeat padding-box;
  opacity: 1;
  outline: none;
  background-size: 100% 100%;
  font: normal normal medium 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  text-transform: uppercase;
  display: flex;
  gap: 15px;
}

.buttonbrowseicon {
  height: 20px;
  padding-right: 5px;
}

.buttonbrowse:hover {
  background: transparent url("../../../../public/img/button1hover.svg") 0% 0%
    no-repeat padding-box;
  background-size: 100% 100%;
}

.buttonbrowse:hover .buttonbrowseicon {
  transform: rotate(45deg);
  transition: 0.5s;
}

.btnanalyse {
  padding: 20px;
  opacity: 1;
  outline: none;
  font: normal normal medium 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  text-transform: uppercase;
  display: flex;
  gap: 50px;
  background-image: url(../../../../public/img/button2hover.svg),
    url(../../../../public/img/button2.svg);
  background-position: left bottom, right top;
  background-size: 85% 70%, 100% 100%;
  background-repeat: no-repeat, no-repeat;
}

.button2hover {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.btnanalyseicon {
  height: 20px;
}

.btnanalyse:hover {
  background-image: url(../../../../public/img/button2.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.btnanalyse:hover .btnanalyseicon {
  transform: rotate(45deg);
  transition: 0.5s;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: auto;
  gap: 10px;
  max-width: 100%;
  padding: 40px 0;
  width: 100%;
  overflow: hidden;
}

/* For 3 files: Flex layout */
.flexContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 100%;
  padding: 40px 0;
  width: 100%;
  overflow: hidden;
}

.filedetails {
  display: flex;
  max-width: 100px;
  font-size: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.filelogo {
  height: 35px;
  width: 30px;
}

.buttoncontainer {
  width: 30%;
}

.fileremoveicon {
  position: absolute;
  height: 12px;
  right: 0;
  cursor: pointer;
}

.errormessage {
  width: 100%;
  color: rgb(248, 0, 41);
  /* left: 20%; */
  /* background-color: rgb(245, 213, 218); */
  /* border-radius: 5px; */
  /* position: absolute; */
  /* bottom: 4px; */
}

.removeButton {
  position: absolute;
  top: -5px;
  right: 0px;
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}

.removeButton:hover {
  color: #cc0000;
}
.fileName {
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 10ch;
}

.test {
  width: 240px;
  height: 10px;
  background-color: #1FC89F;
}

@media only screen and (max-width: 1100px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 resumes per row */
  }

  .flexContainer {
    /* This will only apply when 3 resumes are present */
    justify-content: space-around; /* Ensures 3 items in one row have space */
  }

  .fileName {
    max-width: 8ch;
  }
}
/* Responsive Styles */
@media only screen and (max-width: 700px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr); /* Force 4 columns */
  }

  .fileName {
    max-width: 10ch;
  }
  .resumecontainer {
    overflow: auto;
    flex-direction: column;
    gap: 20px;
    height: calc(100vh - 136px);
    scrollbar-width: none;
  }

  .searchtextcontainer,
  .filesuploadcontainer {
    flex: none;
    width: 100%;
  }

  .pastetextarea2 {
    outline: none;
    /* background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #708ea4; */
    border-radius: 5px;
    width: 100%;
    height: 39vh;
    font-size: 15px;
    text-align: left;
    font: normal normal normal 15px/23px Rubik;
    letter-spacing: 0px;
    color: #1f2e39;
  }

  .uploadmorecontainer {
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    gap: 0;
  }
  .uploadingcontainer {
    padding: 5px;
  }

  .uploadingcontainerforSource {
    padding-top: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    gap: 0;
  }

  .searchfilecontainerforSource {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px #22d6aa33;
    border-radius: 5px;
  }

  .uploadtitle,
  .uploadtitlenote {
    text-align: center;
  }

  .buttonbrowse,
  .btnanalyse {
    padding: 12px;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .filescontainer {
    flex-direction: row;
    gap: 5px;
    padding: 20px 0;
    scrollbar-width: none;
  }

  .filedetails {
    font-size: 8px;
    max-width: 80px;
  }

  .fileremoveicon {
    height: 10px;
  }

  .test {
    width: 180px;
    height: 6px;
  }
}

/* Full-width Container */
.page.fullwidthcontainer {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerblock {
  position: absolute;
  display: block;
  padding: 4.5vh 5vw;
  background-image: url("../../../../public/img/Border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 1300px;
  max-width: 100%;
  max-height: max-content;
}

@media only screen and (max-width: 350px) {
  .resumecontainer {
    overflow: auto;
    flex-direction: column;
    gap: 20px;
    height: calc(100vh - 136px);
    scrollbar-width: none;
  }

  .searchtextcontainer,
  .filesuploadcontainer {
    flex: none;
    width: 100%;
  }

  .uploadmorecontainer {
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    gap: 0;
  }
  .uploadingcontainer {
    padding: 5px;
  }

  .uploadtitle,
  .uploadtitlenote {
    text-align: center;
  }

  .buttonbrowse,
  .btnanalyse {
    padding: 12px;
    font-size: 12px;
  }

  .filescontainer {
    flex-direction: row;
    gap: 5px;
    padding: 20px 0;
    scrollbar-width: none;
  }

  .filedetails {
    font-size: 8px;
    max-width: 80px;
  }

  .fileremoveicon {
    height: 10px;
  }

  .gridContainer {
    grid-template-columns: repeat(4, 1fr); /* Force 4 columns */
  }

  .test {
    width: 180px;
    height: 6px;
  }

  .gridContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 resumes per row */
  }

  .flexContainer {
    /* This will only apply when 3 resumes are present */
    justify-content: space-around; /* Ensures 3 items in one row have space */
  }
}
