@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

/* Style the search container */
.container-boxxxes {
  display: flex;
  align-items: center;
}
.container-boxxxes-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
}
.container-boxxxes-row-main {
  display: flex;
  flex-direction: row;
  gap: 45px;
}
.container-boxxxes .container-boxees-child {
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  border: none;
  background-color: white;
  border-radius: 5px 0px 0px 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95px;
}
.container-boxxxes-row {
  width: 100%;
}
.container-boxxxes button {
  background-color: #1FC89F;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.container-boxees-child1 {
  font-size: 15px;
  font-weight: 500;
  color: #1f2e39;
}
.container-boxees-child2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.container-boxees-child2-1 {
  color: #5a93ed;
  font-size: 40px;
  font-weight: bold;
}
.container-boxees-child2-2 {
  margin-top: 10px;
}
.container-boxees-child2-2 {
  color: #708ea4;
  font-size: 15px;
}
.see-more {
  height: 95px;
  width: 100px;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

/* Dropdown list */
.dropdown__list.open {
  border-radius: 5px 5px 0 0;
  color: black;
  width: 100%;
  font-size: 15px;
}

.dropdown__list {
  background-color: white;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 5px;
  border-left: 1px solid #1f2e39;
  border-right: 1px solid #1f2e39;
  border-top: 1px solid #1f2e39;
  border-bottom: 1px solid #1f2e39;
  height: 5px;
  font-size: 15px;
}

.dropdown__list__parent {
  position: relative;
  z-index: 2;
}

.dropdown__list__child {
  position: absolute;
  background-color: white;
  width: 100%;
  border: 1px solid #1f2e39;
  border-radius: 0 0 5px 5px;
  border-top: none;
  max-height: 208px; /* Adjust height to show 5 items */
  overflow-y: auto; /* Enable vertical scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.dropdown__list__mobile {
  position: absolute;
  background-color: white;
  width: 90%;
  max-width: 820px;
  border: 2px solid #1f2e39;
  border-radius: 0 0 5px 5px;
  border-top: none;
  max-height: 165px; /* Adjust height to show 5 items */
  overflow-y: auto; /* Enable vertical scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  left: 50%;
  transform: translateX(-50%);
}


.dropdown__list__child::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.dropdown__list__child p {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  padding: 9px;
}
.dropdown__list__mobile p {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  padding: 9px;
}

/* second dropdown list */
.dropdown__list_second.secondopen {
  border-radius: 5px 5px 0 0;
  color: black;
}
.dropdown__list_second {
  background-color: white;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 5px 5px 5px 5px;
  border-left: 1px solid #1f2e39; /* Apply border to left side when clicked */
  border-right: 1px solid #1f2e39; /* Apply border to right side when clicked */
  border-top: 1px solid #1f2e39; /* Remove top border when clicked */
  border-bottom: 1px solid #1f2e39;
  height: 5px;
  font-size: 15px;
}
.dropdown__list__parent_second {
  position: relative;
  margin-bottom: 20px;
}
.dropdown__list__child_second {
  position: absolute;
  background-color: white;
  width: 100%;
  border: 1px solid #1f2e39;
  border-radius: 0 0 5px 5px;
  border-top: none;
}
.dropdown__list__child_second p {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  padding: 9px;
}

* {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  box-sizing: border-box;
}

/* Hide the default checkbox */
.checkbox-container input {
  display: none;
}
.checkbox-container {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Add space between items */
  margin-right: 20px;
}

/* Style the custom checkbox */
.checkbox-container .checkmark {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

/* Style the checked state */
.checkbox-container input:checked + .checkmark {
  background-color: #1FC89F;
  border: none;
}

/* Create the checkmark icon */
.checkbox-container .checkmark:after {
  content: "";
  display: none;
  width: 5px;
  height: 9px;
  margin-left: 6px;
  margin-top: 3px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
}

/* Show the checkmark icon when checkbox is checked */
.checkbox-container input:checked + .checkmark:after {
  display: block;
}

/*mobile header*/
.col-mobile {
  background: url(/public/images/Green-Box.svg);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  align-items: center;
  background-size: cover;
  padding: 9px 10px;
}
.pro-mobile {
  font-size: 18px;
  font-weight: bold;
}

/*laptop header*/
.col {
  background: url(/public/images/Green-Box.svg);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  border-radius: 5px;
  align-items: center;
  background-size: cover;
  padding: 9px 20px;
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 10px;
}
.pro {
  font-size: 18px;
  font-weight: bold;
}
.noti {
  height: 20px;
  transition: transform 0.5s ease;
}
.noti:hover {
  transform: rotate(25deg);
}

/*search box*/

/* Style the search container */
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-side {
  flex: 1; /* Takes up remaining space */
}

.right-side {
  margin-left: 10px; /* Adjust margin as needed */
}

/* Style the search container */
.search-container {
  display: flex;
  align-items: center;
}

/* Style the search input */
.search-bar {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
}
.search-container input[type="text"] {
  padding: 15.7px;
  border: none;
  background-color: white;
  border-radius: 5px 0px 0px 5px;
  width: 420px;
  box-shadow: 0px 0px 20px #5b93ed33;
}
.search-container button {
  background-color: #1FC89F;
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 12px;
  cursor: pointer;
}
.search-container button img {
  width: 20px; /* Adjust icon size as needed */
}
.search-container input[type="text"]::placeholder {
  color: #c6d7e3; /* Placeholder color */
}
.button-container {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
}
.button1,
.button2 {
  padding: 10px 10px;
  border: none;
}
.button1 {
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
}
.button2 {
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

/* Style the search button */

.chakraa {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 40px;
}
.full {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/*table*/
.table {
  margin-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
  box-shadow: 0px 0px 20px #5b93ed33;
}
.open {
  margin-top: 5px;
  font-size: 11px;
  color: #708ea4;
}
.number {
  color: #1FC89F;
  font-weight: 600;
  font-size: 13px;
}
.title {
  color: #1f2e39;
  font-weight: 500;
  font-size: 13px;
}
.raw {
  display: flex;
  color: #1f2e39;
  font-size: 13px;
}
.per {
  color: #708ea4;
}
.arrow {
  width: 16px;
}
.colum {
  font-weight: 600;
}
.box {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #1FC89F;
  background-repeat: no-repeat;
  position: relative;
  z-index: 99;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
  transition: all 0.4s ease;
  overflow: hidden;
}
#box {
  display: flex;
  flex-direction: row;
}
.icon {
  transition: transform 0.4s ease; /* Apply transition effect to icon */
}
.box:hover .icon {
  transform: rotate(45deg); /* Rotate icon on hover */
}
.text {
  display: none;
  font-weight: 600;
  font-size: 13px;
}
.box:hover {
  margin-left: -40px;
  width: 80px;
  clip-path: polygon(
    0 12px,
    12px 0,
    100% 0,
    100% calc(100% - 12px),
    calc(100% - 12px) 100%,
    0 100%
  );
}
.boxbtn-parentt {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-end;
}
.box:hover .text {
  display: block;
}
.MuiDrawer-root .MuiButtonBase-root:hover {
  background-color: transparent !important;
}
/* drop down list */

.sorting-remove-table {
  font-size: 15px;
  color: "#1F2E39";
  border: none;
  padding: 5px 10px;
  box-shadow: 0px 0px 20px #5b93ed33;
}

/* Grid */

.grid {
  margin-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
}
.blue {
  color: #5a93ed;
  font-size: 14px;
  font-weight: 600;
}
.gray {
  color: #708ea4;
  font-size: 10px;
}
.grid-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.under-de {
  color: #1f2e39;
  font-weight: 600;
}
.job-id {
  background: url(/public/images/job-id.svg);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
  background-repeat: no-repeat;
  border-radius: 3px 5px 0px 0px;
  margin-bottom: -1px;
}
.job-container {
  display: flex;
  justify-content: flex-end;
}
/*pagination*/

.pagination {
  display: flex;
  position: sticky;
  bottom: 0;
  background: #f3f5f9;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #1f2e39;
  cursor: pointer;
  justify-content: center;
}

.pagination a {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #1FC89F;
  color: white;
  border: 1px solid #1FC89F;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

/*media query of pagination*/

.pagination-mainstick {
  position: sticky;
  bottom: 0;
  text-align: center;
}
.sort-filter-mobile {
  background-color: rgba(33, 214, 170, 1);
  display: inline-flex;
  color: white;
  padding: 15px 10px;
  border-radius: 5px;
  gap: 7px;
}
.pagination-mobile {
  display: flex;
  background: #f3f5f9;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #1f2e39;
  cursor: pointer;
  justify-content: center;
}

.pagination-mobile a {
  color: #000;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.pagination-mobile a.active {
  background-color: #1FC89F;
  color: white;
  border: 1px solid #1FC89F;
  border-radius: 5px;
}

.pagination-mobile a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

/*calendar*/

.custom-input {
  padding: 8px 12px;
  border: 1px solid #707070;
  border-radius: 4px;
  font-size: 14px;
  color: #1f2e39;
  width: 94%;
  display: flex;
  height: 40px;
  margin-bottom: 20px;
}
.react-calendar__month-view__weekdays__weekday {
  color: #5a93ed;
  font-size: 13px;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar {
  border: none !important;
  margin-left: -10px;
}
.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}
.react-calendar__tile--active {
  background: rgba(33, 214, 170, 0.5) !important;
  color: rgba(31, 46, 57, 1) !important;
}
.react-calendar__tile--now {
  background: white !important;
}
.react-calendar__tile--rangeStart {
  border-top-left-radius: 5px !important; /* Make top-left corner rounded */
  border-bottom-left-radius: 5px !important; /* Make bottom-left corner rounded */
  background: rgba(33, 214, 170, 1) !important;
}

.react-calendar__tile--rangeEnd {
  border-top-right-radius: 5px !important; /* Make top-right corner rounded */
  border-bottom-right-radius: 5px !important; /* Make bottom-right corner rounded */
  background: rgba(33, 214, 170, 1) !important;
}
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: white !important;
}
.react-calendar__tile--range {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

/*switch*/

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cbdfff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0;
  bottom: 1px;
  background-color: #708ea4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #1FC89F; /* Change the circle background color here */
  transform: translateX(20px);
}

input:checked + .slider {
  background-color: rgba(33, 214, 170, 0.4);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.green-dot {
  width: 15px;
  height: 15px;
  background-color: #1FC89F;
  border-radius: 50%;
}
.red-dot {
  width: 15px;
  height: 15px;
  background-color: #e77388;
  border-radius: 50%;
}
/*ClientSetting css*/

/*button*/
.Add-Client {
  background-color: rgba(33, 214, 170, 1);
  padding: 7px 28px;
  border-radius: 5px;
  margin-top: -6px;
}
