.resumecontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  width: 100%;
}

.searchtextcontainer {
  width: 30%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: #ffffff;
  color: #1f2e39;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 80%;
  text-align: center;
}

.popup img {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
}

.popup p {
  font: normal normal bold 15px/18px "Rubik", sans-serif;
  margin-top: 10px;
}

.closeButton {
  color: #1FC89F;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.filesuploadcontainer {
  flex: 2;
}

.errorText {
  color: red;
  font-size: 12px;
}
.errormessage {
  width: 100%;
  color: rgb(248, 0, 41);
  border-radius: 5px;
  margin-top: 5px;
}

.searchcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.searchtitle {
  color: #1f2e39;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  font: normal normal bold 17px/23px Rubik;
  margin: 0;
}

.searchlink {
  display: flex;
  font-size: 15px;
  text-align: left;
  font: normal normal medium 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1FC89F;
  text-transform: uppercase;
  gap: 10px;
  align-items: center;
}

.pasteicon {
  height: 15px;
  width: auto;
}

.pastetextarea {
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #708ea4;
  border-radius: 5px;
  width: 100%;
  height: 40vh;
  font-size: 15px;
  text-align: left;
  font: normal normal normal 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  padding-left: 5px;
}

.pastetextarea2 {
  outline: none;
  /* background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #708ea4; */
  border-radius: 5px;
  width: 100%;
  height: 40vh;
  font-size: 15px;
  text-align: left;
  font: normal normal normal 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
}

.searchfilecontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploadingcontainer {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 50px;
  /* padding: 30px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #22d6aa33;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.uploadicon {
  height: 25px;
  width: auto;
  margin-bottom: 20px;
}

.uploadmorecontainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.uploadtitle {
  text-align: center;
  font: normal normal bold 20px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  text-transform: capitalize;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadtitlenote {
  line-height: 18px;
  font-size: 15px;
  text-align: center;
  font: normal normal normal 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonbrowse {
  padding: 18px 30px;
  background: transparent url("../../../../public/img/button1.svg") 0% 0%
    no-repeat padding-box;
  opacity: 1;
  outline: none;
  background-size: 100% 100%;
  font: normal normal medium 18px/26px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  cursor: pointer;
}

.buttonbrowseicon {
  height: 20px;
  width: auto;
}

.buttonbrowse:hover {
  background: transparent url("../../../../public/img/button1hover.svg") 0% 0%
    no-repeat padding-box;
  background-size: 100% 100%;
}

.buttonbrowse:hover .buttonbrowseicon {
  transform: rotate(45deg);
  transition: 0.5s;
}

.btnanalysee {
  padding: 20px;
  opacity: 1;
  outline: none;
  font: normal normal medium 15px/23px Rubik;
  letter-spacing: 0px;
  color: #1f2e39;
  text-transform: uppercase;
  display: flex;
  gap: 30px;
  background-image: url("../../../../public/img/button2hover.svg"),
    url("../../../../public/img/button2.svg");
  background-position: left bottom, right top;
  background-size: 85% 70%, 100% 100%;
  background-repeat: no-repeat, no-repeat;
}

.button2hover {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.btnanalyseicon {
  height: 20px;
}

.btnanalysee:hover {
  background-image: url("../../../../public/img/button2.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.btnanalysee:hover .btnanalyseicon {
  transform: rotate(45deg);
  transition: 0.5s;
}

.filescontainer {
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.filesScrollContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filedetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  position: relative;
}

.filelogo {
  width: 30px;
  height: 35px;
  margin-bottom: 5px;
}

.fileName {
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.removeButton {
  position: absolute;
  top: -5px;
  right: 10px;
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}

.removeButton:hover {
  color: #cc0000;
}
.uploadActionsContainer {
  width: 100%;
  display: flex;
  /* padding: 30px; */
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
  padding: 30px;
}
/* ... existing styles ... */

/* ... existing styles ... */

.progressContainer {
  /* margin-top: 5px; */
  width: 50%;
}

.progressContainer h3 {
  /* margin-bottom: 10px; */
  font: normal normal bold 17px/23px Rubik;
  color: #1f2e39;
}

.progressContainer p {
  margin-top: 3px;
  text-align: center;
  font: normal normal normal 15px/23px Rubik;
  color: #1f2e39;
}

.progressBar {
  width: 100%;
  height: 20px;
}

.progressBarContainer {
  background-color: #e0e0e0;
  border-radius: 10px;
}

.progressBarCompleted {
  background-color: #1FC89F;
  border-radius: 10px;
}

.progressBarLabel {
  font: normal normal medium 12px/20px Rubik;
  color: #1f2e39;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .resumecontainer {
    flex-direction: column;
    gap: 60px;
  }

  .searchtextcontainer {
    width: 100%;
  }

  .filesuploadcontainer {
    width: 100%;
  }

  .pastetextarea2 {
    height: auto;
  }

  .popup {
    width: 90%;
  }

  .progressBar {
    height: 15px;
  }
}
